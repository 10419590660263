// src/RouteConfig.js

import React from "react";
import {
    FaTachometerAlt,
    FaUsers,
    FaConnectdevelop,
} from "react-icons/fa";
import Representatives from "./pages/Representatives";
import Webhooks from "./pages/WebHooks";
import OriginalDashboard from "./pages/ProductionDashboard";
import OriginalRepresentatives from "./pages/ProductionRepresentatives";
import RepresentativePage from "./pages/RepresentativePage";

const routeConfig = [
    {
        path: "/dashboard",
        title: "Dashboard",
        element: OriginalDashboard,
        allowedRoles: ["master", "admin"],
        icon: <FaTachometerAlt />,
        showInMenu: true,
        submenu: [],
    },
    {
        path: "/reps/dashboard",
        title: "Representatives Dashboard",
        element: OriginalRepresentatives,
        allowedRoles: ["master", "admin"],
        icon: <FaUsers />,
        showInMenu: true,
        submenu: [
            {
                title: "Manage Reps",
                path: "/reps",
                showInMenu: true, // Add this property
            },
        ],
    },
    {
        path: "/webhooks",
        title: "Webhooks",
        element: Webhooks,
        allowedRoles: ["master"],
        icon: <FaConnectdevelop />,
        showInMenu: true,
        submenu: [
            {
                title: "Webhooks",
                path: "/webhooks",
                showInMenu: true, // Add this property
            },
        ],
    },
    // Routes that should exist but not appear in the LeftMenu
    {
        path: "/reps",
        title: "Manage Reps",
        element: Representatives,
        allowedRoles: ["master", "admin"],
        showInMenu: false, // Set to false to hide from LeftMenu
    },
    {
        path: "/reps/:id/stats",
        title: "Representative Stats",
        element: RepresentativePage,
        allowedRoles: ["master", "admin"],
        showInMenu: false, // Set to false to hide from LeftMenu
    },
];

export default routeConfig;
